import React, { useContext, useState } from "react";
import { Header, Footer } from "./";
import { NavigationBar } from "../components";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { SiteContext } from "../context";
import Seo from "./Seo";

const validationSchema = Yup.object().shape({
  yourname: Yup.string()
    .label("Your name")
    .required()
    .min(3, "Must have at least 3 characters"),
  youremail: Yup.string()
    .label("Your email address")
    .email("Enter a valid email")
    .required(),
  subject: Yup.string().label("Subject").required(),
  message: Yup.string().label("Message").required(),
});

const PrivacyPolicy = () => {
  const { siteconfig, apiUrl } = useContext(SiteContext);

  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  return (
    <div>
      <Seo
        title={`Privacy Policy | ${siteconfig.shopname}`}
        description="Privacy Policy"
        url={`${apiUrl}/privacy-policy`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />

      <div className="container py-3">
        <NavigationBar section={"Privacy Policy"} goback={false} />

        <div className="row p-3">
          <div className="col-md-9 mt-2">
            <p>Fynix App Privacy Policy</p>
            <p>
              Protecting consumer privacy is very important to us. Our Online
              Privacy Statement is available to inform you about our collection
              and use of personal information so that you can make informed
              choices about how your own information is collected and used. This
              Online Privacy Statement reflects the Fynix policies covering
              personal information collected at this website on or after the
              effective date shown above.
            </p>
            <p>
              We believe that information used responsibly benefits consumers
              and the economy, whether it is information we house on behalf of
              consumers conducting transactions or information we collect on
              behalf of our business clients. For example, we use consumer
              information every day to ensure fast and accurate processing of
              hundreds of thousands of money transfers. In addition, Fynix
              strives to better serve our customers by offering goods and
              services that we believe fit their needs and interests. When
              identifying an opportunity, Fynix may use consumer information it
              has collected in an effort to identify consumers who may be
              interested in that opportunity. For more than few years, consumers
              and businesses have relied on Fynix for safe and reliable service.
              The relationships we have developed through the years have been
              established based on mutual trust. Each day we and employees and
              agents strive to reinforce our commitment to our clients and
              consumers. Central to that commitment is ensuring that we abide by
              a set of privacy policies, which are outlined below. These
              policies guide us in the collection and use of information as well
              as employee responsibilities in protecting your privacy. We use
              personal information about consumers only in a manner consistent
              with these policies.
            </p>
            <p>COLLECTION OF INFORMATION</p>
            <p>
              Fynix collects personal information in order to offer or provide
              goods and services to consumers and our customers. We may obtain
              this information from a variety of sources:
            </p>
            <p>
              · Directly from customer applications, questionnaires and other
              materials submitted to us by customers;
            </p>
            <p>
              · From transactions in which Fynix and consumers are involved;
            </p>
            <p>· From clickthru activity on our web sites; and</p>
            <p>
              · From a variety of third-party sources, such as our business
              customers, government repositories, consumer reporting agencies
              and other financial institutions.
            </p>
            <p>DISCLOSURE OF INFORMATION</p>
            <p>
              Fynix does not disclose customer information to third parties
              without first giving customers an opportunity to opt-out of
              certain kinds of disclosures. There are some disclosures to third
              parties that are not subject to the opt-out, such as disclosures
              necessary to effect, administer or enforce a transaction requested
              by you, to companies that perform services for us or to prevent
              fraud.
            </p>
            <p>
              Finally, Fynix may disclose information about former customers to
              third parties and companies with which Fynix is affiliated, as
              well as unaffiliated third parties. Those disclosures may consist
              of the following:
            </p>
            <p>
              · Financial background information describing a customer's
              financial status;
            </p>
            <p>· Identification information, such as name and address data;</p>
            <p>
              · Transaction information, such as account activity, debit
              activity or credit activity; and
            </p>
            <p>· Other information relating to financial matters.</p>
            <p>RECIPIENTS OF INFORMATION</p>
            <p>
              Fynix may disclose consumer information to companies affiliated
              with Fynix and to companies or other parties not affiliated with
              Fynix
            </p>
            <p>(subject to consumers' opt-out rights)</p>
            <p>These recipients include:</p>
            <p>
              · Financial institutions, such as banks, credit card companies and
              brokerage houses;&nbsp;
            </p>
            <p>
              · Companies that process transactions or provide other services
              for us;
            </p>
            <p>· Government agencies; and</p>
            <p>
              · In addition, under Nigeria law Fynix is permitted to disclose
              customer information to certain recipients, like credit reporting.
            </p>
            <p>
              WE ARE COMMITTED TO PROTECTING THE CONFIDENTIALITY OF PERSONAL
              CONSUMER INFORMATION.
            </p>
            <p>
              Fynix limits access to personal information to those employees of
              Fynix and its affiliates who need it to fulfill their business
              responsibilities.&nbsp;
            </p>
            <p>
              Employees must adhere to Fynix privacy policies. Employees
              violating these policies may be subject to disciplinary action, up
              to and including dismissal.&nbsp;
            </p>
            <p>
              Vendors and other outside contractors we engage are subject to our
              contractual requirements to ensure that sensitive personal
              information is safeguarded.
            </p>
            <p>WE USE APPROPRIATE SECURITY SAFEGUARDS.</p>
            <p>
              At Fynix, security is a priority. We employ appropriate measures
              to protect consumer information against unauthorized access,
              disclosure, alteration or destruction. These may include, among
              others, encryption, physical access security and other appropriate
              technologies. Fynix continually reviews and enhances its security
              systems, as necessary.
            </p>
            <p>
              WE MAINTAIN PROCEDURES TO ASSURE THE QUALITY OF INFORMATION WE
              COLLECT AND WHERE APPLICABLE, WE WILL INFORM CONSUMERS ABOUT HOW
              THEY CAN ACCESS THEIR PERSONAL INFORMATION AND MAKE CORRECTIONS,
              IF NECESSARY.
            </p>
            <p>
              Fynix employs appropriate measures to assure the quality of
              information we collect directly from consumers. Where Fynix
              collects information directly from consumers or sources other than
              our business customers, we permit them, if possible, to dispute or
              correct any erroneous or out-of-date personally identifiable
              information. Of course, this correction would not be possible if
              the information is proprietary to one of our business customers,
              reflects historical transaction information or if correction would
              violate the privacy or legal rights of a third party. Where
              applicable, we abide by laws related to such information. You can
              correct factual errors in your personally identifiable information
              by sending us a written request that credibly shows error. We
              reserve the right to independently verify claims made. To protect
              your privacy and security, we also will take reasonable steps to
              verify your identity before making corrections. We reserve the
              right to assess a service charge for providing you any information
              in connection with your request.
            </p>
            <p>
              THE Fynix WEBSITE IS NOT DIRECTED AT CHILDREN UNDER THE AGE OF 13.
            </p>
            <p>
              Our website is not directed at children under the age of 13 and
              Fynix does not collect or maintain information at our website from
              those we actually know are under the age of 13.
            </p>
            <p>WEBSITE MAY BE LINKED TO OTHER WEBSITES</p>
            <p>
              Fynix may create links to third party websites. Fynix is not
              responsible for the content or privacy practices employed by
              websites that are linked from our website.
            </p>
            <p>OPT OUT RIGHT</p>
            <p>
              Fynix may disclose consumer information to third parties as
              described earlier in this notice. Many of these disclosures are
              beneficial to consumers because they permit goods and services of
              interest to consumers to be offered to consumers at attractive
              rates. We strive to give consumers choices about how their data
              will be used. You have the right to opt-out of (direct us not to
              make) these disclosures when the consumer information is about
              you. Of course, there are some disclosures to third parties that
              are not subject to the opt-out, such as disclosures necessary to
              effect, administer or enforce a transaction requested by you, to
              companies that perform services for us or to prevent fraud. When
              you use this service, you will be asked whether you wish to opt
              out. If you wish to opt out, simply check the appropriate box and
              submit your choice.
            </p>
            <p>
              If you have any questions or indeed comments about our Online
              Privacy Statement, or for some reason believe we have not adhered
              to our statement, please contact:
            </p>
            <p>E-mail the Fynix Data Protection Officer: afambc@gmail.com</p>
            <p>
              We may alter this statement as needed for certain products and
              services and to abide by local laws or regulations around the
              world. Fynix reserves the right to amend or modify this statement
              from time to time. We urge you to review this statement whenever
              you visit in order to obtain the most current statement. You may
              change your choices at any time.
            </p>
            <p>USER DATA DELETION</p>
            <p>
              You may request for the deletion of your account and all personal
              information provided (if you created an account on any of our
              platforms from the website to either android or iOS app to the
              following email address
            </p>
            <p>deletion@fynix.com.ng</p>
            <p>
              Deletion request must provide information such as name, email
              address, phone number and username (they must match the
              information in the database) in the email and if need be the user
              may be contacted for confirmation before deleting the requested
              data.
            </p>
            <p>
              Deletion of data will be done in less than 24hrs of receiving any
              such requests
            </p>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
