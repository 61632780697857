import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {
  Home,
  Login,
  Signup,
  Profile,
  AdminDashboard,
  DriverDashboard,
  Register,
  Account,
  Users,
  UserDashboard,
  UserProfileDisplay,
  UpdatePassword,
  Contact,
  AboutUs,
  SitePages,
  SitePageAdd,
  SitePageUpdate,
  SiteConfigUpdate,
  SiteConfig,
  PageDetail,
  NothingMatch,
  Activation,
  Service,
  ServiceLists,
  Services,
  HsePolicy,
  AdminPhotoAnimation,
  PhotoAnimationAdd,
  PhotoAnimationUpdate,
  RequestQuote,
  Testimonial,
  AdminQuoteRequests,
  AdminTestimonials,
  TestimonialUpdate,
  QuoteRequestUpdate,
  PrivacyPolicy,
  WhatWeDo,
} from "../screens";
import { SiteContext } from "../context";

const SiteRoutes = () => {
  const { isLoggedOn } = useContext(SiteContext);

  const loggedin = localStorage.getItem("loggedin");

  const userloggedOn = loggedin || isLoggedOn;

  const RequireAuth = ({ children }) => {
    if (!userloggedOn) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  const MainNav = () => {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    );
  };

  const AuthNav = () => {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/register" element={<Register />} />
        <Route path="/activation/:rkey/:email" element={<Activation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/request-quote" element={<RequestQuote />} />
        <Route path="/about" element={<AboutUs />} />
        <Route
          path="/users"
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
        <Route
          path="/addpage"
          element={
            <RequireAuth>
              <SitePageAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatepage"
          element={
            <RequireAuth>
              <SitePageUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminpages"
          element={
            <RequireAuth>
              <SitePages />
            </RequireAuth>
          }
        />
        <Route
          path="/addphotoanimation"
          element={
            <RequireAuth>
              <PhotoAnimationAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatephotoanimation"
          element={
            <RequireAuth>
              <PhotoAnimationUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminphotoanimation"
          element={
            <RequireAuth>
              <AdminPhotoAnimation />
            </RequireAuth>
          }
        />
        <Route
          path="/updatequoterequest"
          element={
            <RequireAuth>
              <QuoteRequestUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminquoterequests"
          element={
            <RequireAuth>
              <AdminQuoteRequests />
            </RequireAuth>
          }
        />
        <Route
          path="/adminsiteupdate"
          element={
            <RequireAuth>
              <SiteConfigUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminsiteconfig"
          element={
            <RequireAuth>
              <SiteConfig />
            </RequireAuth>
          }
        />
        <Route path="/hse-policy" element={<HsePolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/what-we-do" element={<WhatWeDo />} />
        <Route path="/page/:id" element={<PageDetail />} />
        <Route path="/our-services" element={<Services />} />
        <Route path="/our-services/:title" element={<Service />} />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path="/changepassword"
          element={
            <RequireAuth>
              <UpdatePassword />
            </RequireAuth>
          }
        />
        <Route
          path="/userprofile"
          element={
            <RequireAuth>
              <UserProfileDisplay />
            </RequireAuth>
          }
        />
        <Route
          path="/account"
          element={
            <RequireAuth>
              <Account />
            </RequireAuth>
          }
        />
        <Route
          path="/admin"
          element={
            <RequireAuth>
              <AdminDashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/user"
          element={
            <RequireAuth>
              <UserDashboard />
            </RequireAuth>
          }
        />
        <Route path="*" element={<NothingMatch />} />
      </Routes>
    );
  };

  return (
    <Router>
      <AuthNav />
    </Router>
  );
};

export default SiteRoutes;
