import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../context";
import { aboutus, intro_video } from "../assets";
import { removeTags, getHeading } from "../utils";
import Carousel from "react-bootstrap/Carousel";
import Accordion from "react-bootstrap/Accordion";
import Seo from "./Seo";

const Home = () => {
  const {
    userData,
    apiUrl,
    siteconfig,
    isLoggedOn,
    categories,
    pages,
    handleCurrentPage,
    isMobile,
  } = useContext(SiteContext);

  const navigate = useNavigate();

  const [animationPhotos, setAnimationPhotos] = useState([]);
  const [clientLogos, setClientLogos] = useState([]);
  const [homeServices, setHomeServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHomeAnimations();
    getClientLogos();
    handleCurrentPage("home");
  }, []);

  const getHomeAnimations = () => {
    fetch(`${apiUrl}/api/homeanimations.php?action=fetch&type=Shop`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setAnimationPhotos(res.rows);
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClientLogos = () => {
    const logos = pages.filter((item) => item.cpagemenu === "Clients");

    setClientLogos(logos);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-content-center my-5">
        <div class="spinner-border text-secondary" role="status" />
      </div>
    );
  }

  return (
    <div>
      <Seo
        title={`Home | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />

      {animationPhotos.length > 0 && (
        <Carousel style={{ backgroundColor: "black" }}>
          {animationPhotos.map((item) => {
            return (
              <Carousel.Item interval={2000} key={item.phid}>
                <img
                  style={{ objectFit: "cover" }}
                  className="d-block w-100"
                  src={`${apiUrl}/${item.phphoto}`}
                  height={isMobile ? 400 : 600}
                  width="100%"
                  alt={item.phtitle}
                />
                <div
                  className="mt-0 p-4 rounded"
                  style={{
                    padding: "5% !important",
                    color: "#fff",
                    position: "absolute",
                    backgroundColor: "#333",
                    opacity: 0.8,
                    left: isMobile ? "5%" : "10%",
                    bottom: isMobile ? "10%" : "40%",
                    width: isMobile ? "90%" : "40%",
                  }}
                >
                  <p className={`text-white ${isMobile ? "h2" : "h1"}`}>
                    {item.phtitle}
                  </p>
                  <p className={isMobile ? "h6" : "h5"}>{item.phcontent}</p>
                  <div className="my-2 d-none d-md-block">
                    <button
                      className="bg-primary border-0 text-white p-2"
                      onClick={() => {
                        handleCurrentPage("whatwedo");
                        navigate("/what-we-do");
                      }}
                    >
                      What We Do
                    </button>{" "}
                    <button
                      className="bg-white border-0 text-dark p-2 mx-2"
                      onClick={() => {
                        handleCurrentPage("about");
                        navigate("/page/about");
                      }}
                    >
                      About Us
                    </button>
                  </div>
                </div>
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      )}

      <div
        style={{ backgroundColor: "azure" }}
        className={isMobile ? "px-2 py-4 my-3" : "px-3 py-5 my-3"}
      >
        <div className="container py-3">
          <div className="row">
            <div className="col-md-6 p-4">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>WHO WE ARE</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Fynix Limited has been established as a haulage company in
                      Nigeria, and our reputation is founded on our ability to
                      deliver services comparable in standard to that obtained
                      in the country and abroad.
                    </p>
                    <p>
                      We can assure you of 100% service delivery, and we deliver
                      promptly no matter what.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>WHY CHOOSE US</Accordion.Header>
                  <Accordion.Body>
                    We have over 10 years experience in reliable transporting
                    goods from one part of the country to another
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-md-6">
              <img
                className="rounded img-fluid"
                src={aboutus}
                width="100%"
                height={isMobile ? 250 : 350}
                alt="about us"
              />
            </div>
          </div>
        </div>
      </div>

      {clientLogos.length > 0 && (
        <div className="bg-white border-bottom my-4 py-2">
          <div className="container">
            <h2 className="py-3">Some of our clients</h2>
            <div className="bg-light text-center py-2">
              {clientLogos.map((item, i) => {
                return (
                  <img
                    key={i}
                    className="img-fluid mx-1 rounded"
                    src={`${apiUrl}/${item.cpagephoto}`}
                    height={100}
                    width={150}
                    alt={item.cpagename}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}

      <div className="bg-white border-bottom my-4">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="bg-light text-center py-3">
                <video controls width="100%">
                  <source
                    autoplay
                    muted
                    loop
                    controls
                    src={intro_video}
                    type="video/mp4"
                  />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
            </div>
            <div className="col-md-5 py-4 px-2">
              <p className="h1 py-2">
                Are you looking for a reliable logistics company?
              </p>
              <p>Worry no more. We can be of help</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
