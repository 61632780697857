import React, { createContext, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

export const SiteContext = createContext();

export const SiteContextProvider = ({ children }) => {
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PRO;
  const [userData, setUserData] = useState(null);
  const [siteconfig, setSiteconfig] = useState({});
  const [pages, setPages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [cart, setCart] = useState([]);
  const [orderTotal, setOrderTotal] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isLoggedOn, setIsLoggedOn] = useState(false);
  const [msg, setMsg] = useState("");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [latestArticles, setLatestArticles] = useState([]);
  const singleProduct = true;

  useEffect(() => {
    getUserDetails();
    getSiteConfig();
    getServices();
  }, []);

  const getServices = () => {
    fetch(`${apiUrl}/api/pages.php?action=fetchallpages`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setPages(res.rows);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getSiteConfig = () => {
    fetch(`${apiUrl}/api/siteupdate.php?action=fetchsiteconfig`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then(async (response) => {
        setSiteconfig(response.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const handleLatestArticles = (latest) => {
    setLatestArticles(latest);
  };

  const slugify = (string) => {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  };

  const getUserDetails = async () => {
    const fromStorageUser = await localStorage.getItem("user");
    const parsedVUser = JSON.parse(fromStorageUser);
    if (fromStorageUser !== null) {
      await setUserData(parsedVUser);
      await setIsLoggedOn(true);
    }
  };

  const handleSignOut = async () => {
    await setUserData(null);
    await setIsLoggedOn(false);
    await localStorage.removeItem("user");
    await localStorage.removeItem("loggedin", false);
  };

  const handleSignIn = async (data) => {
    const storedUser = JSON.stringify(data);

    await setUserData(data);
    await setIsLoggedOn(true);
    await localStorage.setItem("user", storedUser);
    await localStorage.setItem("loggedin", true);
  };

  const formatNumberK = (num) => {
    if (num > 0) {
      let numprice = num / 1000;

      let num = numprice.toString();
      return `${num}`;
    } else {
      return "0";
    }
  };

  const contextValue = {
    apiUrl,
    categories,
    pages,
    msg,
    isLoggedOn,
    userData,
    userToken,
    siteconfig,
    isMobile,
    currentPage,
    latestArticles,
    formatNumberK,
    slugify,
    handleSignOut,
    handleSignIn,
    setUserData,
    getServices,
    handleCurrentPage,
    handleLatestArticles,
  };

  return (
    <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>
  );
};
