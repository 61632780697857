import React, { useContext, useState } from "react";
import { Header, Footer } from "./";
import { NavigationBar } from "../components";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { SiteContext } from "../context";
import Seo from "./Seo";

const WhatWeDo = () => {
  const { siteconfig, apiUrl } = useContext(SiteContext);

  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  return (
    <div>
      <Seo
        title={`What We Do | ${siteconfig.shopname}`}
        description="What We Do"
        url={`${apiUrl}/what-we-do`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />

      <div className="container py-3">
        <NavigationBar section={"What We Do"} goback={false} />

        <div className="row p-3">
          <div className="col-md-9 mt-2">
            <p>
              Fynix Limited has been established as a haulage company in
              Nigeria, and our reputation is founded on our ability to deliver
              services comparable in standard to that obtained in the country
              and abroad. We can assure you of 100% service delivery, and we
              deliver promptly no matter what.{" "}
            </p>

            <p>
              We are currently registered and hauling for Flour Mills of Nigeria
              Plc and have since maintained good working and honest business
              relationship.{" "}
            </p>

            <p>
              We are looking forward to offering you our special service
              packaged reserved for those who seek the best.
            </p>

            <p>
              Please accept our sincere appreciation for your kind attention.
            </p>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WhatWeDo;
